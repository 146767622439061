export const GeneratePanelFormFieldType = {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  GROUP_TITLE: 'groupTitle',
  INPUT: 'input',
  SLIDER: 'slider',
  VALUE: 'value',
} as const;

export type GeneratePanelFormFieldType = (typeof GeneratePanelFormFieldType)[keyof typeof GeneratePanelFormFieldType];
