<div class="toolbar white-scroll">
  @if (toolbarItems.length) {
    <ul class="generative-toolbar-menu">
      @for (menuItem of toolbarItems; track menuItem) {
        <li>
          <button
            blurAfterFocus
            class="generative-toolbar-menu__button"
            matTooltipPosition="right"
            type="button"
            [class.generative-toolbar-menu__button_active]="menuItem.active()"
            [class.generative-toolbar-menu__button_disabled]="menuItem.disabled()"
            [matTooltip]="menuItem.title"
            (click)="menuItem.clickHandler()"
          >
            <svg-icon class="generative-toolbar-menu__icon" [name]="menuItem.icon" />
          </button>
        </li>
      }
    </ul>
  }

  <section class="generative-toolbar-panel" [class.generative-toolbar-panel_expanded]="isPanelExpanded()">
    @if (generatePanelMenuItem.active()) {
      <div class="generative-toolbar-panel_container">
        <header class="generative-toolbar-panel__header">
          <h3 class="generative-toolbar-panel__header-title" [textContent]="generatePanelMenuItem.title"></h3>
          <button blurAfterFocus class="generative-toolbar-panel__header-button" type="button" (click)="closeGeneratePanel()">
            <svg-icon class="generative-toolbar-icon" name="chevronRight" />
          </button>
        </header>

        <div class="generative-toolbar-panel__content">
          <app-generate-panel></app-generate-panel>
        </div>
      </div>
    }
  </section>
</div>
