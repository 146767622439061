import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  Renderer2,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { PushPipe } from '@ngrx/component';

import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { FeatureFlagService } from '../../modules/feature-flag/feature-flag.service';
import { SvgIconModule } from '../../modules/svg-icon/svg-icon.module';
import { FrontendEventsService } from '../../services/frontend-events.service';
import { ControlMode, Direct } from './control-modes.interface';
import { ControlModesService } from './control-modes.service';

/**
 * @class ControlModesComponent
 * @description Представляет компонент управления режимами.
 *
 * @memberof components
 *
 * @example
 * // Использование
 * <app-control-modes></app-control-modes>
 */
@Component({
  selector: 'app-control-modes',
  standalone: true,
  imports: [SvgIconModule, PushPipe, AsyncPipe, MatTooltip, BlurAfterFocusDirective],
  templateUrl: './control-modes.component.html',
  styleUrl: './control-modes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlModesComponent implements OnInit, AfterViewChecked {
  /**
   * Представляет ссылку на элемент HTMLLIElement, используемую в представлении элемента в режиме символов.
   *
   * @typedef {ElementRef<HTMLLIElement>} characterModeItemRef
   */
  readonly characterModeItemRef = viewChild<ElementRef<HTMLLIElement>>('characterModeItem');

  /**
   * Представляет ссылку на элемент элемента режима транспортного средства в представлении.
   *
   * @typedef {ViewChild<ElementRef<HTMLLIElement>>} VehicleModeItemRef
   */
  readonly vehicleModeItemRef = viewChild<ElementRef<HTMLLIElement>>('vehicleModeItem');

  /**
   * Представляет кнопку "Картография".
   *
   * @typedef {ViewChild<ElementRef<HTMLButtonElement>>} CartographicBtn
   */
  readonly cartographicBtn = viewChild<ElementRef<HTMLButtonElement>>('cartographicBtn');

  /**
   * Представляет элемент freecamBtn.
   *
   * @typedef {ElementRef<HTMLButtonElement>} freecamBtn
   */
  readonly freecamBtn = viewChild<ElementRef<HTMLButtonElement>>('freecamBtn');

  /**
   * Представляет режим контроля для Control Model.
   *
   * @typedef {string} ControlMode
   */
  readonly controlMode = this.controlModelService.controlMode;

  /**
   * Переменная, указывающая на то, сохраняются ли данные в данный момент.
   * @type {boolean}
   */
  readonly isSaving = this.controlModelService.isSaving;

  /**
   * Получает значение свойства isHidden из объекта store для скрытия режимов управления в случае включенного демо режима
   * @type {boolean}
   */
  readonly isHidden = this.controlModelService.isHidden;

  /**
   * Переменная, указывающая, является ли модель управления неинициализированной.
   * @type {boolean}
   */
  readonly isUninitialized = this.controlModelService.isUninitialized;

  /**
   * Переменная, указывающая на режим движения.
   * @type {boolean}
   */
  readonly isMovementMode = this.controlModelService.isMovementMode;

  /**
   * Поле, содержащее предыдущий активный тип действия без движения.
   * @type {any}
   */
  readonly prevActiveNonMovementType = this.controlModelService.prevActiveNonMovementType;

  /**
   * Получает значение правого отступа, основанное на состоянии функционального флага 'DT_BUTTON'.
   *
   * @returns {boolean} - Возвращает true, если функциональный флаг 'DT_BUTTON' включён, в противном случае возвращается false.
   */
  @HostBinding('class.move-right')
  rightMargin = this.featureFlagService.isFeatureOn('MAP_CENTER') || this.featureFlagService.isFeatureOn('DT_BUTTON');

  /**
   * Получает значение правого отступа, основанное на состоянии функционального флага 'DT_BUTTON'.
   *
   * @returns {boolean} - Возвращает true, если функциональный флаг 'DT_BUTTON' включён, в противном случае возвращается false.
   */
  @HostBinding('class.move-right_adition')
  rightMarginAddition = this.featureFlagService.isFeatureOn('DT_BUTTON') && this.featureFlagService.isFeatureOn('MAP_CENTER');

  /**
   * Создаёт новый экземпляр класса.
   *
   * @param {Document} document - Объект document, внедренный в конструктор.
   * @param {ControlModesService} controlModelService - Сервис модели контроля.
   * @param {FrontendEventsService} frontendEventsService - Сервис фронтенд событий.
   * @param {Renderer2} renderer - Объект Renderer2 используется для манипуляции с DOM-элементами.
   * @param {DestroyRef} destroyRef - Внедренный экземпляр DestroyRef.
   * @param {ActivatedRoute} activatedRoute - Сервис активированного маршрута.
   * @param {FeatureFlagService} featureFlagService - Сервис для управления функциональными флагами.
   *
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private controlModelService: ControlModesService,
    private frontendEventsService: FrontendEventsService,
    private renderer: Renderer2,
    private destroyRef: DestroyRef,
    private activatedRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
  ) {}

  /**
   * Инициализирует компонент - проиводит подписку на событие изменения режима управления
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.frontendEventsService
      .updateControlModeEvent()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (controlMode) => {
        const currentControlMode = this.controlMode().mode;

        if (controlMode.mode === 'freecam' && (currentControlMode === 'character' || currentControlMode === 'vehicle')) {
          this.setHover(false, currentControlMode);
        }

        this.controlModelService.setControlMode({ controlMode, sendReq: false });

        if (this.activatedRoute.snapshot?.queryParams && (currentControlMode === 'character' || currentControlMode === 'vehicle')) {
          this.activatedRoute.snapshot.queryParams.data && (await this.frontendEventsService.updateQueryParams('data'));
          this.activatedRoute.snapshot.queryParams.coords && (await this.frontendEventsService.updateQueryParams('coords'));
        }
      });
  }

  /**
   * Хук жизненного цикла, который вызывается после проверки представления.
   * Этот метод выполняется каждый раз, когда представление и все дочерние представления были проверены механизмом обнаружения изменений.
   * Используйте этот хук для любых дополнительных задач инициализации или очистки, которые нужно выполнить после проверки представления.
   *
   * @return {void}
   */
  ngAfterViewChecked(): void {
    if (this.controlMode().mode === 'freecam' && this.document.pointerLockElement) {
      this.setBtnHover(false, 'cartographic');
    }
  }

  /**
   * Устанавливает режим управления для сервиса модели управления.
   *
   * @param {ControlMode} controlMode - Устанавливаемый режим управления.
   * @return {void}
   */
  setControlMode(controlMode: ControlMode): void {
    this.controlModelService.setControlMode({ controlMode });
  }

  /**
   * Выход из режима размещения символов.
   * @returns {void}
   */
  exitCharacterPlacementMode(): void {
    this.controlModelService.exitCharacterPlacementMode();
  }

  /**
   * Устанавливает состояние наведения элемента на основе предоставленного флага.
   *
   * @param {boolean} flag - Флаг указывает должно ли устанавливаться состояние наведения или нет.
   * @param {('character' | 'vehicle')} type - Тип режима. Доступные значения - 'character' и 'vehicle'.
   * @returns {void}
   */
  setHover(flag: boolean, type: Extract<Direct['mode'], 'character' | 'vehicle'>): void {
    const nativeElement = type === 'character' ? this.characterModeItemRef()?.nativeElement : this.vehicleModeItemRef()?.nativeElement;

    flag ? this.renderer.addClass(nativeElement, 'modes__item_hover') : this.renderer.removeClass(nativeElement, 'modes__item_hover');
  }

  /**
   * Устанавливает состояние наведения для кнопки.
   *
   * @param {boolean} flag - Флаг, указывающий, следует ли установить состояние наведения или нет.
   * @param {'cartographic' | 'freecam'} type - Тип кнопки, для которой нужно установить состояние наведения.
   *
   * @return {void}
   */
  setBtnHover(flag: boolean, type: 'cartographic' | 'freecam'): void {
    const nativeElement = type === 'freecam' ? this.freecamBtn()?.nativeElement : this.cartographicBtn()?.nativeElement;

    flag
      ? this.renderer.addClass(nativeElement, 'modes__item-btn_hover')
      : this.renderer.removeClass(nativeElement, 'modes__item-btn_hover');
  }
}
