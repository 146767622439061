import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { of, take } from 'rxjs';

import { MAP_CENTER } from '../../constants/app.constant';
import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { SvgIconModule } from '../../modules/svg-icon/svg-icon.module';
import { BackendEventsService } from '../../services/backend-events.service';
import { ControlModesService } from '../control-modes/control-modes.service';

/**
 * Компонент для центрирования карты
 *
 * @param {string} selector Селектор компонента
 * @param {boolean} standalone Флаг, указывающий на то, что компонент является автономным
 * @param {Array} imports Массив зависимостей компонента
 * @param {string} templateUrl Путь к файлу шаблона компонента
 * @param {string} styleUrl Путь к файлу стилей компонента
 * @param {string} changeDetection Стратегия обнаружения изменений
 */
@Component({
  selector: 'app-center-map',
  standalone: true,
  imports: [BlurAfterFocusDirective, MatTooltip, SvgIconModule],
  templateUrl: './center-map.component.html',
  styleUrl: './center-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenterMapComponent {
  /**
   * Флаг, указывающий на то, что происходит перевод карты
   */
  readonly isTranslating = signal(false);

  /**
   * Ссылка на объект уничтожения
   */
  readonly #destroyRef = inject(DestroyRef);

  /**
   * Сервис для обработки событий бэкенда
   */
  readonly #backendEventsService = inject(BackendEventsService);

  /**
   * Сервис для управления моделями контроля.
   * @type {ControlModesService}
   */
  readonly #controlModesService = inject(ControlModesService);

  /**
   * Представляет режим контроля для Control Modes.
   *
   * @typedef {string} ControlMode
   */
  readonly isMovementMode = this.#controlModesService.isMovementMode;

  /**
   * Метод для перевода камеры к центру карты
   */
  translateCamera2CenterMap(): void {
    if (this.isTranslating()) {
      return;
    }

    this.isTranslating.set(true);

    (this.isMovementMode() ? this.#backendEventsService.exitCharacterPlacementMode() : of(true))
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.translateCamera());
  }

  /**
   * Переводит камеру на центр карты.
   *
   * @private
   * @returns {void}
   */
  private translateCamera(): void {
    this.#backendEventsService
      .translateCamera(MAP_CENTER)
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.isTranslating.set(false));
  }
}
