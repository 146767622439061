<div class="toolbar white-scroll" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
  @if (toolbarRoutes.length) {
    <ul class="toolbar-menu">
      @for (route of toolbarRoutes; track route.path) {
        @if (route.path | uppercase | convertType | isMenuItemVisible) {
          <li>
            <button
              blurAfterFocus
              class="toolbar-menu__button"
              matTooltipPosition="right"
              type="button"
              [class.toolbar-menu__button_active]="
                route.path | isToolbarMenuButtonActive: activePanel() : isPCGActive() : isUserManualActive()
              "
              [class.toolbar-menu__button_disabled]="route.path === 'search' && isDisabledSearch()"
              [matTooltip]="route.data?.title"
              [matTooltipDisabled]="route.path === 'search' && isDisabledSearch()"
              (click)="route.path === 'search' && isDisabledSearch() ? nonMainMapSnackBar() : toggleSubpanel($event, route.path)"
            >
              <svg-icon class="toolbar-menu__icon" [name]="route.data?.icon || route.path | convertType" />
            </button>
          </li>
        }
      }
    </ul>
  }
  <section class="toolbar-panel" [class.toolbar-panel_expanded]="activePanel()">
    @if (data$ | ngrxPush; as data) {
      <header class="toolbar-panel__header" [class.toolbar-panel__header_no-border]="data?.noBorder" [hidden]="data?.hiddenTitle">
        <h3 class="toolbar-panel__header-title" [textContent]="data?.title"></h3>
        <button blurAfterFocus class="toolbar-panel__header-button" type="button" (click)="closeSubpanels()">
          <svg-icon class="toolbar-icon" name="chevronLeft" />
        </button>
      </header>
    }
    <router-outlet />
  </section>

  <app-generative-toolbar *appFeatureFlag="'GENERATION_PANEL'" />
</div>
