import { Injectable, inject } from '@angular/core';
import { filter, fromEvent } from 'rxjs';
import { share } from 'rxjs/operators';

import { EventTypes } from '../enums/event-types.enum';
import { WINDOW } from '../tokens/window.token';

/**
 * Сервис для обработки событий, инициированных пользователем.
 */
@Injectable({ providedIn: 'root' })
export class EventService {
  /**
   * Представляет объект окна.
   *
   * @typedef {Object} Window
   * @property {string} location - URL текущей страницы.
   * @property {number} innerWidth - Ширина области содержимого окна браузера.
   * @property {number} innerHeight - Высота области содержимого окна браузера.
   *
   * @global
   */
  #window = inject(WINDOW);

  /**
   * Представляет наблюдаемый поток событий отпускания кнопки мыши.
   * @type {Observable<MouseEvent>}
   * @name mouseUp$
   * @memberof module: myApp
   */
  mouseUp$ = fromEvent(this.#window, EventTypes.MOUSEUP).pipe(share());
  /**
   * Наблюдаемый объект, генерирующий KeyboardEvent при нажатии клавиши.
   *
   * @name keyboardDown$
   * @type {Observable<KeyboardEvent>}
   */
  keyboardDown$ = fromEvent<KeyboardEvent>(this.#window, EventTypes.KEYDOWN).pipe(share());

  /**
   * Observable для события нажатия правой кнопки мыши.
   *
   * @type {Observable<MouseEvent>} Observable, который выпускает событие MouseEvent при нажатии правой кнопки мыши.
   */
  rightMouseDown$ = fromEvent(this.#window, EventTypes.MOUSEDOWN).pipe(
    filter((event) => (event as MouseEvent).button === 2),
    share(),
  );

  /**
   * Наблюдаемый объект для события "mouseup", сгенерированного кликом правой кнопкой мыши.
   *
   * @type {Observable<MouseEvent>}
   */
  rightMouseUp$ = fromEvent(this.#window, EventTypes.MOUSEUP).pipe(
    filter((event) => (event as MouseEvent).button === 2),
    share(),
  );
}
