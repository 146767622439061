import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { IconType } from '../../../../modules/svg-icon/generated-svg/svg-icons';
import { SvgIconModule } from '../../../../modules/svg-icon/svg-icon.module';
import { GeneratePanelFormFieldType } from './generate-panel-form-field-types.enum';

/**
 * Интерфейс для описания поля формы
 * @interface FormField
 * @property {GeneratePanelFormFieldType} type - Тип поля формы
 * @property {IconType} [iconName] - Необязательное название иконки
 * @property {string} name - Название поля
 * @property {boolean | number | string | undefined} [value] - Значение поля
 * @property {number} [min] - Минимальное значение
 * @property {number} [max] - Максимальное значение
 * @property {Function} [clickHandler] - Обработчик клика
 */
interface FormField {
  /**
   * Тип поля формы для генерации панели
   * @type {GeneratePanelFormFieldType}
   */
  type: GeneratePanelFormFieldType;

  /**
   * Название иконки.
   * @type {IconType}
   */
  iconName?: IconType;

  /**
   * Переменная, содержащая строковое значение имени
   * @type {string}
   */
  name: string;

  /**
   * Значение переменной может быть логическим типом, числом, строкой или неопределенным.
   *
   * @type {boolean | number | string | undefined}
   */
  value?: boolean | number | string | undefined;

  /**
   * Минимальное значение, которое может принимать переменная
   * @type {number}
   */
  min?: number;

  /**
   * Максимальное значение.
   * @type {number}
   */
  max?: number;

  /**
   * Обработчик клика.
   *
   * @returns {void} Ничего не возвращает.
   */
  clickHandler?(): void;
}

/**
 * Компонент для панели генерации.
 * @param {string} selector - селектор компонента
 * @param {boolean} standalone - флаг, указывающий на автономность компонента
 * @param {Array} imports - массив импортов для компонента
 * @param {string} templateUrl - путь к шаблону компонента
 * @param {Array} styleUrls - массив путей к стилям компонента
 * @param {string} changeDetection - стратегия обнаружения изменений
 */
@Component({
  selector: 'app-generate-panel',
  standalone: true,
  imports: [SvgIconModule, MatSliderModule, MatCheckboxModule, MatButtonModule, CommonModule, NgxMaskDirective],
  providers: [provideNgxMask()],
  templateUrl: './generate-panel.component.html',
  styleUrls: ['./generate-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratePanelComponent {
  /**
   * Массив полей формы.
   * @type {FormField[]}
   */
  formFields: FormField[] = [
    { type: GeneratePanelFormFieldType.VALUE, iconName: 'generativePloshad', name: $localize`Площадь разработки, га`, value: '-' },
    { type: GeneratePanelFormFieldType.VALUE, iconName: 'generativePloshad', name: $localize`Площадь участка, га`, value: '0,5431' },
    { type: GeneratePanelFormFieldType.INPUT, iconName: 'generativePlotnost', name: $localize`Плотность застройки`, value: '100' },
    { type: GeneratePanelFormFieldType.CHECKBOX, iconName: 'generativePlotnost', name: $localize`Ограничение строений`, value: false },
    { type: GeneratePanelFormFieldType.SLIDER, iconName: 'generativePlotnost', name: $localize`Высотность`, value: 50, min: 5, max: 100 },
    { type: GeneratePanelFormFieldType.GROUP_TITLE, name: $localize`Результаты генерации` },
    { type: GeneratePanelFormFieldType.VALUE, iconName: 'generativeGroups', name: $localize`СПП (целевая), м<sup>2</sup>`, value: '-' },
    { type: GeneratePanelFormFieldType.VALUE, iconName: 'generativeGroups', name: $localize`СПП (расчётная), м<sup>2</sup>`, value: '-' },
    { type: GeneratePanelFormFieldType.VALUE, iconName: 'generativeGroups', name: $localize`Школы, мест`, value: '-' },
    { type: GeneratePanelFormFieldType.BUTTON, name: $localize`Сгенерировать`, clickHandler: (): void => {} },
  ];

  /**
   * Генерирует тип поля формы для панели
   * @param {Function} GeneratePanelFormFieldType - функция для генерации типа поля формы
   */
  generatePanelFormFieldType = GeneratePanelFormFieldType;
}
