import { Injectable, computed } from '@angular/core';

import { Status } from '../enums/status.enum';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { Models3dService } from '../modules/toolbar/3dmodels/models3d.service';
import { BuildingCard } from '../modules/toolbar/favorites/favorites.interface';
import { FavoritesService } from '../modules/toolbar/favorites/favorites.service';

/**
 * Сервис для работы с карточками зданий.
 * @Injectable указывает, что этот сервис может быть внедрен в другие компоненты или сервисы.
 * @param {Object} providedIn - указывает, что сервис будет предоставлен в корневом модуле приложения.
 */
@Injectable({ providedIn: 'root' })
export class BuildingCardService {
  /**
   * Возвращает статус загрузки, вычисленный на основе статусов загрузки 3D моделей и избранных элементов.
   * @returns {Status} Статус загрузки (LOADED, LOADING, UNINITIALIZED)
   */
  readonly loadingStatus = computed(() => {
    const { LOADED, LOADING, UNINITIALIZED } = Status;

    if (!this.featureFlagService.isFeatureOn('FAVORITES') && !this.featureFlagService.isFeatureOn('3D-MODELS')) {
      return UNINITIALIZED;
    }

    if (
      (this.featureFlagService.isFeatureOn('3D-MODELS') ? this.models3dService.status() === LOADING : true) ||
      (this.featureFlagService.isFeatureOn('FAVORITES') ? this.favoritesService.loadingStatus() === LOADING : true)
    ) {
      return LOADING;
    } else if (
      (this.featureFlagService.isFeatureOn('3D-MODELS') ? this.models3dService.status() === LOADED : true) &&
      (this.featureFlagService.isFeatureOn('FAVORITES') ? this.favoritesService.loadingStatus() === LOADED : true)
    ) {
      return LOADED;
    } else {
      return UNINITIALIZED;
    }
  });

  /**
   * Вычисляет список избранных зданий для существующего здания.
   * @returns {Array} Список избранных зданий для существующего здания.
   */
  favouritesForExistingBuilding = computed(() => {
    if (!this.featureFlagService.isFeatureOn('FAVORITES')) {
      return [];
    }

    if (!this.featureFlagService.isFeatureOn('3D-MODELS')) {
      return this.favoritesService.favourites();
    }

    const favouriteEntities = this.featureFlagService.isFeatureOn('FAVORITES') ? this.favoritesService.favouriteEntities() : {};
    const buildings = this.featureFlagService.isFeatureOn('3D-MODELS') ? this.models3dService.buildings() : [];

    const favorites = buildings.reduce((acc, { buildingId }) => {
      if (favouriteEntities[buildingId]) {
        acc.push(favouriteEntities[buildingId]!);
      }

      return acc;
    }, [] as BuildingCard[]);

    const favoritesBuildingId = favorites.map(({ buildingId }) => buildingId);

    return this.favoritesService.favourites().filter((item) => {
      return favoritesBuildingId.includes(item.buildingId);
    });
  });

  /**
   * Конструктор класса, принимающий сервисы для работы с флагами функциональности, 3D моделями и избранными элементами.
   *
   * @param {FeatureFlagService} featureFlagService Сервис для работы с флагами функциональности.
   * @param {Models3dService} models3dService Сервис для работы с 3D моделями.
   * @param {FavoritesService} favoritesService Сервис для работы с избранными элементами.
   */
  constructor(
    private featureFlagService: FeatureFlagService,
    private models3dService: Models3dService,
    private favoritesService: FavoritesService,
  ) {}

  /**
   * Загружает действия в зависимости от настроек приложения.
   * Если включена функциональность избранных элементов, вызывает метод getFavoritesAction() из favoritesService.
   * Если включена функциональность 3D моделей, вызывает метод getBuildings() из models3dService.
   * @returns {void}
   */
  loadAction(): void {
    if (this.featureFlagService.isFeatureOn('FAVORITES')) {
      this.favoritesService.getFavoritesAction();
    }

    if (this.featureFlagService.isFeatureOn('3D-MODELS')) {
      this.models3dService.getBuildings();
    }
  }
}
