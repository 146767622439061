import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  SIGNALING_URL: $ENV.SIGNALING_URL,
  MATCHMAKER_URL: $ENV.MATCHMAKER_URL ?? 'https://manager.meta.mos.ru/signallingserver',
  API_URL: $ENV.API_URL,
  AUTH_TYPE: $ENV.AUTH_TYPE || '',
  WEB_ADMIN_URL: $ENV.WEB_ADMIN_URL || 'https://webadmin.metapolis.su',
  hasIntoPage: true,
  disableUEPing: false,
  disableShowPSExtendedLogs: false,
  DT_URL: 'https://smart.mos.ru',
  GLOB_URL: 'dictionaries/v1/839/values?attributes=URL_ADDRESS',
};
