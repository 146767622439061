import { Injectable, signal } from '@angular/core';

/**
 * @class PCGService
 *
 * Представляет службу PCGService, которую можно использовать для переключения состояния isActive.
 *
 * @property {boolean} isGeographicToolPanelActive - Указывает, активна ли служба или нет.
 *
 * @method toggleGenUrbanDesignPanel - Включает/выключает панель с генеративным проектированием.
 */
@Injectable({
  providedIn: 'root',
})
export class PCGService {
  /**
   * isActive
   *
   * Переменная, представляющая текущее состояние сигнала.
   *
   * @typedef {Object} isGeographicToolPanelActive
   * @property {boolean} value - Текущее значение сигнала.
   *
   * @param {boolean} initialValue - Начальное значение сигнала.
   * @returns {isGeographicToolPanelActive} - Текущее состояние сигнала.
   */
  isGeographicToolPanelActive = signal(false);

  /**
   * Включает/выключает панель с генеративным проектированием.
   *
   * @returns {void}
   */
  toggleGenUrbanDesignPanel(): void {
    this.isGeographicToolPanelActive.update((val) => !val);
  }

  /**
   * Устанавливает активность панели географических инструментов.
   * @param {boolean} isGenUrbanDesignPanelActive - Флаг, указывающий, активна ли панель генерации городского дизайна.
   * @returns {void}
   */
  setGeographicToolPanel(isGenUrbanDesignPanelActive: boolean): void {
    this.isGeographicToolPanelActive.set(isGenUrbanDesignPanelActive);
  }
}
