<div class="generate-panel__settings-list">
  @for (formField of formFields; track formField.name) {
    @switch (formField.type) {
      @case (generatePanelFormFieldType.VALUE) {
        <div class="generate-panel__settings-list_item">
          <div class="generate-panel__settings-list_item-icon">
            <svg-icon class="generative-toolbar-icon" [name]="formField.iconName" />
          </div>
          <div class="generate-panel__settings-list_item-container">
            <div class="generate-panel__settings-list_item-container-block">
              <div class="generate-panel__settings-list_item-name">
                <span [innerHTML]="formField.name"></span>
              </div>
              <div class="generate-panel__settings-list_item-value">
                <span>{{ formField.value }}</span>
              </div>
            </div>
          </div>
        </div>
      }
      @case (generatePanelFormFieldType.INPUT) {
        <div class="generate-panel__settings-list_item">
          <div class="generate-panel__settings-list_item-icon">
            <svg-icon class="generative-toolbar-icon" [name]="formField.iconName" />
          </div>
          <div class="generate-panel__settings-list_item-container">
            <div class="generate-panel__settings-list_item-container-block">
              <div class="generate-panel__settings-list_item-name">
                <span [innerHTML]="formField.name"></span>
              </div>
              <div class="generate-panel__settings-list_item-value">
                <input
                  class="input generate-panel__settings-list_item-value_input"
                  decimalMarker="."
                  i18n-placeholder="@@generate.input.placeholder"
                  mask="separator.2"
                  placeholder="Введите"
                  type="text"
                  [attr.value]="formField.value"
                />
              </div>
            </div>
          </div>
        </div>
      }
      @case (generatePanelFormFieldType.CHECKBOX) {
        <div class="generate-panel__settings-list_item">
          <div class="generate-panel__settings-list_item-icon">
            <svg-icon class="generative-toolbar-icon" [name]="formField.iconName" />
          </div>
          <div class="generate-panel__settings-list_item-container">
            <div class="generate-panel__settings-list_item-container-block">
              <div class="generate-panel__settings-list_item-name">
                <span [innerHTML]="formField.name"></span>
              </div>
              <div class="generate-panel__settings-list_item-value">
                <mat-checkbox [checked]="formField.value" />
              </div>
            </div>
          </div>
        </div>
      }
      @case (generatePanelFormFieldType.SLIDER) {
        <div class="generate-panel__settings-list_item">
          <div class="generate-panel__settings-list_item-icon">
            <svg-icon class="generative-toolbar-icon" [name]="formField.iconName" />
          </div>
          <div class="generate-panel__settings-list_item-container">
            <div class="generate-panel__settings-list_item-container-block">
              <div class="generate-panel__settings-list_item-name">
                <span [innerHTML]="formField.name"></span>
              </div>
              <div class="generate-panel__settings-list_item-value">
                <span>{{ formField.value }}</span>
              </div>
            </div>
            <div class="generate-panel__settings-list_item-container-slider-block">
              <div class="generate-panel__settings-list_item-container-slider-block_min">
                <span>{{ formField.min }}</span>
              </div>
              <div class="generate-panel__settings-list_item-container-slider-block_slider">
                <mat-slider class="generate-panel-mat-slider">
                  <input matSliderThumb [max]="formField.max" [min]="formField.min" [(value)]="formField.value" />
                </mat-slider>
              </div>
              <div class="generate-panel__settings-list_item-container-slider-block_max">
                <span>{{ formField.max }}</span>
              </div>
            </div>
          </div>
        </div>
      }
      @case (generatePanelFormFieldType.GROUP_TITLE) {
        <div class="generate-panel__settings-list_item">
          <div class="generate-panel__settings-list_item-group-title" [innerHTML]="formField.name"></div>
        </div>
      }
      @case (generatePanelFormFieldType.BUTTON) {
        <div class="generate-panel__settings-list_item-footer">
          <button
            class="generate-panel__settings-list_button"
            mat-raised-button
            type="button"
            [textContent]="formField.name"
            (click)="formField?.clickHandler()"
          ></button>
        </div>
      }
    }
  }
</div>
