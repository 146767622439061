import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { Status } from '../../../../enums/status.enum';
import { BackendEventsService } from '../../../../services/backend-events.service';
import { BuildingCard } from '../models3d.interface';

type Models3DState = {
  status: Status;
  error: string | null;
  buildings: BuildingCard[];
};

/**
 * Представляет начальное состояние 3D моделей.
 *
 * @typedef {object} Models3DState
 * @property {Status} status - Текущий статус моделей.
 * @property {Error | null} error - Ошибка, возникшая во время загрузки, если таковая имеется.
 * @property {Array} buildings - Список зданий, связанных с моделями.
 */
const initialState: Models3DState = {
  status: Status.UNINITIALIZED,
  error: null,
  buildings: [],
};

/**
 * Models3DStore - это хранилище, которое управляет состоянием и методами, связанными с 3D-моделями зданий.
 *
 * @typedef {Object} Models3DStore
 * @property {Status} status - Статус хранилища. Может быть 'LOADING', 'LOADED' или 'ERROR'.
 * @property {Array} buildings - Массив зданий.
 *
 * @method getBuildings - Извлекает список зданий из backend-сервиса events и обновляет состояние хранилища.
 *
 * @param {BuildingCard} building - Объект карточки здания, для которого нужно переключить статус избранного.
 */
export const Models3DStore = signalStore(
  { providedIn: 'root' },
  withDevtools('models3D'),
  withState(initialState),
  withMethods((store, backedEventsService = inject(BackendEventsService)) => ({
    getBuildings: rxMethod<void>(
      pipe(
        tap(() => patchState(store, { status: Status.LOADING })),
        switchMap(() =>
          backedEventsService.getCurrentBuildings().pipe(
            tapResponse({
              next: (buildings) => {
                const sortedBuildings = buildings.toSorted((a, b) => a.name.localeCompare(b.name));
                patchState(store, { buildings: sortedBuildings });
              },
              error: console.error,
              finalize: () => patchState(store, { status: Status.LOADED }),
            }),
          ),
        ),
      ),
    ),
  })),
);

export type Models3DStore = InstanceType<typeof Models3DStore>;
