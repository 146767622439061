import { Injectable, inject } from '@angular/core';

import { Status } from '../../../enums/status.enum';
import { Models3DStore } from './store/models3d.store';

/**
 * Сервис для управления 3D моделями.
 *
 * @remarks
 * Этот сервис предоставляет методы взаимодействия с Models3DStore, который хранит информацию о зданиях и их статусе.
 *
 * @publicApi
 */
@Injectable({ providedIn: 'root' })
export class Models3dService {
  /**
   * Представляет переменную под названием 'store', инициализированную с помощью внедрения 'Models3DStore'.
   * @type {Models3DStore}
   */
  store = inject(Models3DStore);

  /**
   * Массив зданий.
   *
   * @type {Array.<Object>}
   * @readonly
   */
  buildings = this.store.buildings;

  /**
   * Получает статус магазина.
   *
   * @returns {string} Текущий статус магазина.
   */
  status = this.store.status;

  /**
   * Получает здания из хранилища.
   *
   * @returns {void}
   */
  getBuildings(): void {
    this.store.status() === Status.UNINITIALIZED && this.store.getBuildings();
  }
}
