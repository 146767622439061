import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';

import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { ClickOutsideModule } from '../../directives/click-outside/click-outside.module';
import { FeatureFlagDirective } from '../../modules/feature-flag/feature-flag.directive';
import { SvgIconModule } from '../../modules/svg-icon/svg-icon.module';
import { LayersComponent } from '../../modules/toolbar/layers/layers.component';
import { SearchComponent } from '../../modules/toolbar/search/search.component';
import { ConvertTypePipe } from '../../pipe/convert-type/convert-type.pipe';
import { GeneratePhaseType, GeneratePhases } from './generative-toolbar-phases.enum';
import { GenerativeToolbarMenuItem } from './generative-toolbar.type';
import { GeneratePanelComponent } from './subpanels/generate-panel/generate-panel.component';

/**
 * Компонент панели инструментов.
 * @param {string} selector - Селектор компонента.
 * @param {boolean} standalone - Флаг, указывающий на автономность компонента.
 * @param {Array} imports - Массив импортов компонента.
 * @param {string} templateUrl - Путь к файлу шаблона компонента.
 * @param {string} styleUrl - Путь к файлу стилей компонента.
 * @param {string} changeDetection - Стратегия обнаружения изменений.
 */
@Component({
  selector: 'app-generative-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    SearchComponent,
    ClickOutsideModule,
    LayersComponent,
    RouterModule,
    PushPipe,
    SvgIconModule,
    ConvertTypePipe,
    FeatureFlagDirective,
    MatTooltipModule,
    BlurAfterFocusDirective,
    GeneratePanelComponent,
  ],
  templateUrl: './generative-toolbar.component.html',
  styleUrl: './generative-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerativeToolbarComponent implements OnInit {
  /**
   * Объект objectLayerMenuItem, представляющий элемент меню панели инструментов.
   * @type {GenerativeToolbarMenuItem}
   * @property {string} id - Идентификатор элемента меню.
   * @property {string} icon - Иконка элемента меню.
   * @property {string} title - Заголовок элемента меню.
   * @property {Signal<boolean>} disabled - Сигнал, указывающий на доступность элемента меню.
   * @property {Signal<boolean>} active - Сигнал, указывающий на активность элемента меню.
   * @property {Function} clickHandler - Обработчик события клика на элемент меню.
   */
  readonly objectLayerMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_AreaSelect',
    icon: 'generativeObjectLayer',
    title: $localize`Область`,
    disabled: signal(false),
    active: signal(false),
    clickHandler: (): void => {
      this.objectLayerMenuItem.active.update((prevState) => {
        return !prevState;
      });
    },
  };

  /**
   * Элемент меню панели инструментов для дороги.
   * @type {GenerativeToolbarMenuItem}
   * @readonly
   * @property {string} id - Идентификатор элемента.
   * @property {string} icon - Иконка элемента.
   * @property {string} title - Название элемента.
   * @property {Signal<boolean>} disabled - Сигнал, указывающий на то, отключен ли элемент.
   * @property {Signal<boolean>} active - Сигнал, указывающий на то, активен ли элемент.
   * @property {Function} clickHandler - Обработчик нажатия на элемент.
   */
  readonly roadMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_RoadEntry',
    icon: 'generativeRoadWithPlus',
    title: $localize`Дорога`,
    disabled: signal(false),
    active: signal(false),
    clickHandler: (): void => {
      this.roadMenuItem.active.update((prevState) => {
        return !prevState;
      });
    },
  };

  /**
   * Генерирует элемент панели меню.
   * @type {GenerativeToolbarMenuItem}
   * @readonly
   * @property {string} id - Идентификатор элемента.
   * @property {string} icon - Иконка элемента.
   * @property {string} title - Заголовок элемента.
   * @property {Signal<boolean>} disabled - Сигнал для отключения элемента.
   * @property {Signal<boolean>} active - Сигнал для активации элемента.
   * @property {Function} clickHandler - Обработчик клика на элемент.
   */
  readonly generatePanelMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_BuildingSettings',
    icon: 'generativeHomeWorkGear',
    title: $localize`Сгенерировать`,
    disabled: signal(false),
    active: signal(true),
    clickHandler: (): void => {
      this.openGeneratePanel();
    },
  };

  /**
   * Элемент меню панели инструментов с возможностью генерации курсора.
   * @type {GenerativeToolbarMenuItem}
   * @property {string} id - Идентификатор элемента меню
   * @property {string} icon - Иконка элемента меню
   * @property {string} title - Название элемента меню
   * @property {Signal<boolean>} disabled - Сигнал, указывающий на доступность элемента меню
   * @property {Signal<boolean>} active - Сигнал, указывающий на активность элемента меню
   * @property {Function} clickHandler - Обработчик события нажатия на элемент меню
   */
  readonly cursorMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_BuildingDataInfo',
    icon: 'generativeCursor',
    title: $localize`Информация`,
    disabled: signal(true),
    active: signal(false),
    clickHandler: (): void => {
      this.cursorMenuItem.active.update((prevState) => {
        return !prevState;
      });
    },
  };

  /**
   * Элемент панели инструментов для корзины.
   * @type {GenerativeToolbarMenuItem}
   * @property {string} id - Идентификатор элемента.
   * @property {string} icon - Иконка элемента.
   * @property {string} title - Название элемента.
   * @property {Signal<boolean>} disabled - Сигнал, указывающий на доступность элемента.
   * @property {Signal<boolean>} active - Сигнал, указывающий на активность элемента.
   * @property {Function} clickHandler - Обработчик события нажатия на элемент.
   */
  readonly trashbinMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_Bin',
    icon: 'generativeTrashbin',
    title: $localize`Удалить`,
    disabled: signal(true),
    active: signal(false),
    clickHandler: (): void => {
      this.trashbinMenuItem.active.update((prevState) => {
        return !prevState;
      });
    },
  };

  /**
   * Объект, представляющий пункт меню для отмены действия.
   * @type {GenerativeToolbarMenuItem}
   * @property {string} id - Идентификатор пункта меню.
   * @property {string} icon - Иконка пункта меню.
   * @property {string} title - Название пункта меню.
   * @property {Signal<boolean>} disabled - Сигнал, указывающий на доступность пункта меню.
   * @property {Signal<boolean>} active - Сигнал, указывающий на активность пункта меню.
   * @property {Function} clickHandler - Обработчик события нажатия на пункт меню.
   */
  readonly revertMenuItem: GenerativeToolbarMenuItem = {
    id: 'Button_Undo',
    icon: 'generativeArrowLeft',
    title: $localize`Отменить`,
    disabled: signal(false),
    active: signal(false),
    clickHandler: (): void => {
      this.revertMenuItem.active.update((prevState) => {
        return !prevState;
      });
    },
  };

  /**
   * Функция, вычисляющая, раскрыто ли панель
   * @returns {boolean} Возвращает true, если панель раскрыта, иначе false
   */
  readonly isPanelExpanded = computed(() => {
    return this.generatePanelMenuItem.active();
  });

  /**
   * Массив элементов панели инструментов.
   * @type {GenerativeToolbarMenuItem[]}
   */
  toolbarItems: GenerativeToolbarMenuItem[] = [
    this.objectLayerMenuItem,
    this.roadMenuItem,
    this.generatePanelMenuItem,
    this.cursorMenuItem,
    this.trashbinMenuItem,
    this.revertMenuItem,
  ];

  /**
   * Текущая фаза генерации.
   *
   * @type {WritableSignal<GeneratePhaseType>}
   */
  currentPhase = signal(GeneratePhases.OFF);

  /**
   * Устанавливает текущую фазу генерации.
   *
   * @param {GeneratePhaseType} phase - Фаза генерации.
   * @returns {void}
   */
  setCurrentPhase(phase: GeneratePhaseType): void {
    switch (phase) {
      case GeneratePhases.OFF: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(true);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.INITIAL: {
        this.objectLayerMenuItem.disabled.set(false);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(true);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.AREA_SELECTION: {
        this.objectLayerMenuItem.disabled.set(false);
        this.objectLayerMenuItem.active.set(true);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(true);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(false);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.AREA_CREATED: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(false);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(false);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.ENTRY_POINT_SELECTION: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(false);
        this.roadMenuItem.active.set(true);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(true);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.GENERATION_PARAMS: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(false);
        this.generatePanelMenuItem.active.set(false);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(false);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(false);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.GENERATION_IN_PROGRESS: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(true);
        this.generatePanelMenuItem.active.set(true);

        this.cursorMenuItem.disabled.set(true);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(true);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.GENERATION_COMPLETE: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        this.generatePanelMenuItem.disabled.set(false);
        //this.generatePanelMenuItem.active.set(true); - preserve previous state of generatePanelMenuItem

        this.cursorMenuItem.disabled.set(false);
        this.cursorMenuItem.active.set(false);

        this.trashbinMenuItem.disabled.set(false);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }

      case GeneratePhases.DATA_INFO_SELECTION: {
        this.objectLayerMenuItem.disabled.set(true);
        this.objectLayerMenuItem.active.set(false);

        this.roadMenuItem.disabled.set(true);
        this.roadMenuItem.active.set(false);

        // preserve previous state of generatePanelMenuItem
        //this.generatePanelMenuItem.disabled.set(false);
        //this.generatePanelMenuItem.active.set(true);

        this.cursorMenuItem.disabled.set(false);
        this.cursorMenuItem.active.set(true);

        this.trashbinMenuItem.disabled.set(false);
        this.trashbinMenuItem.active.set(false);

        this.revertMenuItem.disabled.set(true);
        this.revertMenuItem.active.set(false);

        break;
      }
    }
  }

  /**
   * Открывает панель генерации.
   * @returns {void}
   */
  openGeneratePanel(): void {
    this.generatePanelMenuItem.active.set(true);
  }

  /**
   * Закрывает панель генерации.
   * @returns {void}
   */
  closeGeneratePanel(): void {
    this.generatePanelMenuItem.active.set(false);
  }

  /**
   * Инициализирует компонент
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.setCurrentPhase(GeneratePhases.GENERATION_PARAMS); // Temporary. Will be taken from UE
  }
}
